import { Component } from '@angular/core';

@Component({
  selector: 'ngx-checkout-layout',
  styleUrls: ['./checkout.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-checkout-header></ngx-checkout-header>
      </nb-layout-header>

      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

    </nb-layout>
  `,
})
export class CheckoutLayoutComponent { }
