import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { StorageMap } from '@ngx-pwa/local-storage';

import { environment } from '../../environments/environment';

@Injectable()
export class BasketService {

  apis: any;
  config: any;
  storageKeys: any;

  token: any;

  private dataStore: any = {
    basketValue: 0,
    basketCount: 0,
    products: [],
    builders: [],
  };

  private _basket = new BehaviorSubject<any>(this.dataStore);


  constructor(
    private http: HttpClient,
    private storage: StorageMap,
  ) {
    this.apis = environment.apis;
    this.config = environment.config;
    this.storageKeys = environment.storage;

  }


  get basket() {
    return this._basket.asObservable();
  }


  addProductToBasket(product, quantity) {
    const newProduct = Object.assign({}, product);
    newProduct.quantity = quantity;

    let basketValue = 0;
    let basketCount = 0;


    const matchedItem = this.dataStore.products.find(item => item.id === newProduct.id);

    if (matchedItem) {
      matchedItem.quantity = matchedItem.quantity + newProduct.quantity;
    } else {
      this.dataStore.products.push(newProduct);
    }

    this.dataStore.products.forEach((item, index) => {
      basketValue += (item.price * item.quantity);
      basketCount += item.quantity;
    });


    this.dataStore.builders.forEach((item, index) => {
      basketValue += item.price;
      basketCount++;
    });


    this.dataStore.basketValue = Math.round(basketValue * 1e2) / 1e2;
    this.dataStore.basketCount = basketCount;


    this.storage.set(this.storageKeys.basket, this.dataStore).subscribe(() => {
      this._basket.next(Object.assign({}, this.dataStore));
    });
  }


  modifyProductQty(product, quantity) {
    let basketValue = 0;
    let basketCount = 0;


    const matchedItem = this.dataStore.products.find(item => item.id === product.id);

    if (matchedItem) {
      matchedItem.quantity = quantity;
      let spliceIndex = -1;

      this.dataStore.products.forEach((item, index) => {
        if ((item.id === product.id) && (item.quantity === 0)) {
          spliceIndex = index;
        } else {
          basketValue += (item.price * item.quantity);
          basketCount += item.quantity;
        }
      });


      this.dataStore.builders.forEach((item, index) => {
        basketValue += item.price;
        basketCount++;
      });


      if (spliceIndex >= 0) {
        this.dataStore.products.splice(spliceIndex, 1);
      }

      this.dataStore.basketValue = Math.round(basketValue * 1e2) / 1e2;
      this.dataStore.basketCount = basketCount;


      this.storage.set(this.storageKeys.basket, this.dataStore).subscribe(() => {
        this._basket.next(Object.assign({}, this.dataStore));
      });

    }
  }


  addBuilderToBasket(builder) {
    const newBuilder = Object.assign({}, builder);

    let basketValue = 0;
    let basketCount = 0;

    this.dataStore.builders.push(newBuilder);


    this.dataStore.products.forEach((item, index) => {
      basketValue += (item.price * item.quantity);
      basketCount += item.quantity;
    });

    this.dataStore.builders.forEach((item, index) => {
      basketValue += item.price;
      basketCount++;
    });

    this.dataStore.basketValue = Math.round(basketValue * 1e2) / 1e2;
    this.dataStore.basketCount = basketCount;


    this.storage.set(this.storageKeys.basket, this.dataStore).subscribe(() => {
      this._basket.next(Object.assign({}, this.dataStore));
    });
  }


  removeBuilder(builder) {
    const matchedItem = this.dataStore.builders.find(item => item.identifier === builder.identifier);

    let basketValue = 0;
    let basketCount = 0;


    if (matchedItem) {
      let spliceIndex = -1;


      this.dataStore.products.forEach((item, index) => {
        basketValue += (item.price * item.quantity);
        basketCount += item.quantity;
      });


      this.dataStore.builders.forEach((item, index) => {
        if (item.identifier === builder.identifier) {
          spliceIndex = index;
        } else {
          basketValue += item.price;
          basketCount++;
        }
      });


      if (spliceIndex >= 0) {
        this.dataStore.builders.splice(spliceIndex, 1);
      }

      this.dataStore.basketValue = Math.round(basketValue * 1e2) / 1e2;
      this.dataStore.basketCount = basketCount;


      this.storage.set(this.storageKeys.basket, this.dataStore).subscribe(() => {
        this._basket.next(Object.assign({}, this.dataStore));
      });

    }
  }


  loadBasket() {

    this.storage.get(this.storageKeys.basket).subscribe((basket) => {
      if (basket) {
        this.dataStore = basket;
        this._basket.next(Object.assign({}, this.dataStore));
      }
    });

  }


  deleteBasket() {
    this.storage.delete(this.storageKeys.basket).subscribe((basket) => {
      this.dataStore = {
        basketValue: 0,
        basketCount: 0,
        products: [],
        builders: [],
      },
      this._basket.next(Object.assign({}, this.dataStore));
    });

  }


  placeOrder(products, builders) {

    const now = new Date();

    const dateMilliseconds = now.getTime();
    const tzoffset = now.getTimezoneOffset() * 60000; // offset in milliseconds

    const neutralNow = (new Date(dateMilliseconds - tzoffset));

    const d = neutralNow.toISOString();

    const purchaseType = 'KIOSK';
    const orderType = 'Take Away';

    let orderTypeString = '';

    // tslint:disable-next-line:max-line-length
    orderTypeString = `Order Type: ${orderType}`;

    const note = orderTypeString;

    const order = {
      due: d,
      purchaseItems: [],
      purchaseBuilderItems: [],
      purchaseType: purchaseType,
      storeId: this.config.storeid,
      note: note,
      workplaceSlotId: null,
      kioskId: this.config.kioskId,
    };

    // adding products
    for (let i = 0; i < products.length; i++) {
      const item = {
        itemId: products[i].id,
        quantity: products[i].quantity,
      };
      order.purchaseItems.push(item);
    }

    // adding builders
    for (let i = 0; i < builders.length; i++) {
      const item = {
        displayName: builders[i].displayName,
        name: builders[i].name,
        price: builders[i].price,
        purchaseItemComponents: [],
      };

      for (let k = 0; k < builders[i].items.length; k++) {
        const builderItem = builders[i].items[k];
        const component = {
          extraPrice: builderItem.extraPrice,
          grouping: builderItem.grouping,
          name: builderItem.name,
          price: builderItem.price,
        };

        item.purchaseItemComponents.push(component);

      }

      order.purchaseBuilderItems.push(item);
    }

    const headers = new HttpHeaders().set('Bludot-Api-Key', environment.apis.BludotApiKey);

    return this.http.post<any>(`${this.apis.bludot}/public/ext-purchase/`, order, { headers })
      .pipe(map(data => {
        return data;
      }));

  }


  setOrderToInProgress(transaction) {

    const headers = new HttpHeaders().set('Bludot-Api-Key', environment.apis.BludotApiKey);

    const purchaseStatus = {
      purchaseId: transaction.order.id,
      purchaseStatus: 'IN_PROGRESS',
      paymentId: transaction.paymentId,
    };

    return this.http.post<any>(`${this.apis.bludot}/public/ext-purchase/set-purchase-status`, purchaseStatus, { headers })
      .pipe(map(data => {
        return data;
      }));

  }

  delete(order) {

    const headers = new HttpHeaders().set('Bludot-Api-Key', environment.apis.BludotApiKey);

    return this.http.delete<any>(`${this.apis.bludot}/public/ext-purchase/${order.id}`, { headers })
      .pipe(map(data => {
        return data;
      }));

  }

}
