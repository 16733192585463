import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { Router } from '@angular/router';
import { BasketService } from '../../../_services/basket.service';
import { AllergenService } from '../../../_services/allergen.service';
import { environment } from '../../../../environments/environment';

import { NbDialogService } from '@nebular/theme';
import { ConfirmDialogComponent } from '../../../@theme/components/dialogs/confirm-dialog/confirm-dialog.component';


import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {


  config: any;

  private destroy$: Subject<void> = new Subject<void>();

  basketValue = 0;
  basketCount = 0;

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private router: Router,
    private basketService: BasketService,
    private allergenService: AllergenService,
    private breakpointService: NbMediaBreakpointsService,
    private dialogService: NbDialogService
  ) {

    this.config = environment.config;

    this.basketService.basket.subscribe(data => {

      if (data) {
        this.basketValue = data.basketValue;
        this.basketCount = data.basketCount;
      }


    });

    this.basketService.loadBasket();

  }

  ngOnInit() {


    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => {

      });

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  navigateHome() {
    //this.menuService.navigateHome();
    this.router.navigate(['/pages/categories']);
    return false;
  }


  completeOrder() {
    this.router.navigate(['/checkout/basket']);
    return false;
  }


  clearBasket() {

    console.log('basketCount', this.basketCount);

    if (this.basketCount) {

      let dialogRef = this.dialogService.open(ConfirmDialogComponent, {
        context: {
          title: 'Cancel Order?',
          text: 'Are you sure you wish to cancel your current order?',
          dismissText: 'No, go back',
          confirmText: 'Yes, Cancel order'
        },
      });

      dialogRef.onClose.subscribe((confirm: any) => {
        if (confirm) {
          this.basketService.deleteBasket();
          this.allergenService.deleteAlergens();
          this.router.navigate(['/pages/categories']);
        }
      });

    } else {

      this.router.navigate(['/pages/categories']);
    }


    return false;
  }


}
