import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectFilter'
})
export class ObjectFilterPipe implements PipeTransform {

  transform(value: any, key?: any, val?: any): any {


    const cats = value.filter(function(el) {
      return el[key] === val;
    });

    return cats;
  }


}
