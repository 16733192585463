import { Component, Input } from '@angular/core';
import { filter } from "rxjs/operators";
import { environment } from '../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'bdot-catlist',
  styleUrls: ['./category-list.component.scss'],
  templateUrl: './category-list.component.html',
})
export class CategoryListComponent {


  apis: any;
  config: any;

  @Input()
  items: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.apis = environment.apis;
    this.config = environment.config;


  }


  ngOnInit() {


  }



  getCategoryImage(category) {
    // apis.images+'/'+config.storeid+'/'+product.featuredImage.name
    let image = './assets/images/placeholder1.jpg'
    if (category.image) {
      image = this.apis.images + '/' + this.config.storeid + '/' + category.image.name;
    }
    return image;
  }



  goToCategory(id) {
    this.router.navigate([`/pages/categories/${id}`]);
  }


}
