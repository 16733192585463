import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { StorageMap } from '@ngx-pwa/local-storage';
import { environment } from '../../../../../environments/environment';
import { ifError } from 'assert';

import { AllergenService } from '../../../../_services/allergen.service';

@Component({
  selector: 'bdot-ai-dialog',
  styleUrls: ['./ai-dialog.component.scss'],
  templateUrl: './ai-dialog.component.html',
})
export class AIDialogComponent {


  allergens: any;



  constructor(
    private storage: StorageMap,
    private allergenService: AllergenService,
    protected ref: NbDialogRef<AIDialogComponent>,
  ) {

    this.allergenService.allergens.subscribe(data => {

      if (data) {
        this.allergens = data.allergens;
      }


    });


    this.allergenService.loadAllergens();


  }



  selectAllergen(allergen) {

    if (allergen.selected) {
      this.allergenService.removeAllergen(allergen);
    } else {
      this.allergenService.addAllergen(allergen);
    }


  }




  dismiss() {
    this.ref.close();
  }


}
