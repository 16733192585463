import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { StorageMap } from '@ngx-pwa/local-storage';

import { environment } from '../../environments/environment';

@Injectable()
export class AllergenService {

  storageKeys: any;


  private dataStore: any = {
    allergens: environment.allergenList,
  };

  private _allergens = new BehaviorSubject<any>(this.dataStore);


  constructor(
    private http: HttpClient,
    private storage: StorageMap,
  ) {
    this.storageKeys = environment.storage;

  }


  get allergens() {
    return this._allergens.asObservable();
  }


  addAllergen(allergen) {


    console.log('add allergen', allergen);

    this.dataStore.allergens.forEach((item, index) => {
      if (allergen.id === item.id) {
        item.selected = true;
      }
    });

    this.storage.set(this.storageKeys.allergens, this.dataStore).subscribe(() => {
      this._allergens.next(Object.assign({}, this.dataStore));
    });
  }



  removeAllergen(allergen) {

    console.log('remove allergen', allergen);

    this.dataStore.allergens.forEach((item, index) => {
      if (allergen.id === item.id) {
        item.selected = false;
      }
    });

    this.storage.set(this.storageKeys.allergens, this.dataStore).subscribe(() => {
      this._allergens.next(Object.assign({}, this.dataStore));
    });
  }






  loadAllergens() {

    this.storage.get(this.storageKeys.allergens).subscribe((allergens) => {
      if (allergens) {
        this.dataStore = allergens;
        this._allergens.next(Object.assign({}, this.dataStore));
      }
    });

  }


  deleteAlergens() {

    this.dataStore.allergens.forEach((item, index) => {
      item.selected = false;
    });

    this.storage.set(this.storageKeys.allergens, this.dataStore).subscribe(() => {
      this._allergens.next(Object.assign({}, this.dataStore));
    });

  }




}
