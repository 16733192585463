import { Component, AfterViewInit } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { environment } from '../../../../environments/environment';
import { NbDialogService } from '@nebular/theme';

import { AllergenService } from '../../../_services/allergen.service';
import { AIDialogComponent } from '../../../@theme/components/dialogs/ai-dialog/ai-dialog.component';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  templateUrl: './footer.component.html',
})
export class FooterComponent implements AfterViewInit {



  allergens: any;
  allergenCount = 0;

  constructor(
    private storage: StorageMap,
    private dialogService: NbDialogService,
    private allergenService: AllergenService,
  ) {


    this.allergenService.allergens.subscribe(data => {

      if (data) {

        this.allergens = data.allergens;

        this.allergenCount = 0;
        this.allergens.forEach((item, index) => {
          if (item.selected) {
            this.allergenCount++;
          }
        });

      }


    });

   this.allergenService.loadAllergens();
  }


  ngAfterViewInit(){
    
    if(!this.allergenCount){
      console.log('ngAfterViewInit');
      this.selectAllergens();
    }

  }



  selectAllergens() {

    this.dialogService.open(AIDialogComponent);

  }






}
