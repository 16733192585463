import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { Router, NavigationEnd } from '@angular/router';
import { BasketService } from '../../../_services/basket.service';
import { environment } from '../../../../environments/environment';

import { NbDialogService } from '@nebular/theme';
import { ConfirmDialogComponent } from '../../../@theme/components/dialogs/confirm-dialog/confirm-dialog.component';


import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'ngx-checkout-header',
  styleUrls: ['./checkout-header.component.scss'],
  templateUrl: './checkout-header.component.html',
})
export class CheckoutHeaderComponent implements OnInit, OnDestroy {


  config: any;
  page = '';

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private router: Router,
    private basketService: BasketService,
    private breakpointService: NbMediaBreakpointsService,
    private dialogService: NbDialogService
  ) {

    this.config = environment.config;

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {

        switch (val.url) {
          case '/checkout/payment':
            this.page = 'payment'
            break;
          case '/checkout/basket':
            this.page = 'basket'
            break;
          default:
            this.page = ''
        }
      }

    });




  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }



  pay() {
    this.router.navigate(['/checkout/payment']);
    return false;
  }

  goBackToMenu() {
    this.router.navigate(['/pages/categories']);
    return false;
  }

  goBackToBasket() {
    this.router.navigate(['/checkout/basket']);
    return false;
  }




}
