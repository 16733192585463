import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'bdot-stepper',
  styleUrls: ['./stepper.component.scss'],
  templateUrl: './stepper.component.html',
})
export class StepperComponent {


  @Input() count: number;
  @Input() min: number;

  @Output() stepValue: EventEmitter<number> = new EventEmitter();

  constructor(
  ) {
  }


  increase() {
    this.count++;
    this.stepValue.emit(this.count);
  }


  decrease() {
    this.count--;
    if (this.count < this.min) {
      this.count = this.min;
    }
    this.stepValue.emit(this.count);
  }




}
