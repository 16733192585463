import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'bdot-confirm-dialog',
  styleUrls: ['./confirm-dialog.component.scss'],
  templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent {


  @Input() title: any;
  @Input() text: any;
  @Input() dismissText: any;
  @Input() confirmText: any;



  constructor(
    protected ref: NbDialogRef<ConfirmDialogComponent>,
  ) {

  }



  dismiss() {
    this.ref.close();
  }

  confirm() {
    this.ref.close(true);
  }


}
